import React from "react";
import Checkmark from "../../components/Checkmark/Checkmark";

export const ConfirmedContestants = () => {
  return (
    <center>
      <div
        className="col-md-4"
        style={{ height: "1000px", background: "red", marginTop: "-700px" }}
      >
        <Checkmark />
      </div>
    </center>
  );
};
